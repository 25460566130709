import { event } from "@otto-ec/global-resources/event";
import { fragment } from "@otto-ec/global-resources/fragment";

const TRACKING_DATA_ATTRIBUTE = "data-tracking";

function _onLinkClicked(event: Event) {
  const oldPathPlusQuery = window.location.pathname + window.location.search;
  const element = event.target as Element;
  const newLocation = element?.getAttribute("href") || "";

  if (newLocation.slice(0, newLocation.indexOf("#")) === oldPathPlusQuery) {
    /*                             */
    window.location.hash = "";
    event.preventDefault();
    return false;
  }
}

function removeHashIfUrlIsUnchanged(link: Element) {
  link.removeEventListener("click", _onLinkClicked);
  link.addEventListener("click", _onLinkClicked);
}

function init() {
  event.delegate(document.body, "mousedown", "a", function (this: Element) {
    const href: string = this?.getAttribute("href") || "";
    const trackingData: string = this?.getAttribute(TRACKING_DATA_ATTRIBUTE) || "";

    if (trackingData) {
      removeHashIfUrlIsUnchanged(this);
      this.setAttribute(
        "href",
        <string>fragment.push(
          {
            t: trackingData,
          },
          href
        )
      );
      this.removeAttribute(TRACKING_DATA_ATTRIBUTE);
    }
  });
}

let reptileTrackingLinkInitialized = false;

export function registerTrackingLink() {
  /*            */
  if (reptileTrackingLinkInitialized) {
    return;
  }
  reptileTrackingLinkInitialized = true;

  init();
}
