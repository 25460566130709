import { eventQBus, Topics } from "../../types/EventQBus";
import { HistoryMergeType, HistoryPushType, HistoryShiftType } from "../../types/EventHistory";

/**
 *
 *
 *
 */
let blockInitialPopstate = false;

/**
 *
 *
 */
function isEphemeral() {
  const { ephemeral = false } = window.history.state || {};
  return ephemeral;
}

/**
 *
 *
 *
 */
function mergeEventState<Topic extends keyof Topics>(topic: Topic | undefined, data: Topics[Topic][0], url: string) {
  const { events: currentEvents = {}, ...currentData } = window.history.state || {};
  const events = topic ? { [topic]: data } : {};
  window.history.replaceState(
    {
      ...currentData,
      events: { ...currentEvents, ...events },
    },
    "",
    url || getRelativeLocation()
  ); /*                       */
}

/**
 *
 *
 *
 *
 *
 */
function pushEventState<Topic extends keyof Topics>(
  topic: Topic | undefined,
  ephemeral: boolean,
  data: Topics[Topic][0],
  url: string
) {
  const events = topic ? { [topic]: data } : {};
  const state = { ephemeral: ephemeral, events };

  if (isEphemeral()) {
    window.history.replaceState(state, "", url);
  } else {
    window.history.pushState(state, "", url);
  }
}

/**
 *
 *
 *
 *
 *
 *
 */
function shiftEventState(force: boolean) {
  if (force || isEphemeral()) {
    window.removeEventListener("popstate", onPopState);
    window.addEventListener("popstate", onShiftState);
    history.back();
  }
}

/**
 *
 */
function onPopState(event: PopStateEvent) {
  const events = (event.state && event.state.events) || {};

  if (blockInitialPopstate) {
    blockInitialPopstate = false;
    return;
  }

  for (const topic in events) {
    /*                                                          */
    /*                                             */
    if (events.hasOwnProperty(topic)) {
      eventQBus.emit(topic as keyof Topics, events[topic]);
    }
  }
}

/**
 *
 *
 *
 *
 *
 */
function onShiftState(event: PopStateEvent) {
  window.removeEventListener("popstate", onShiftState);
  window.addEventListener("popstate", onPopState);
}

/**
 *
 *
 *
 *
 */
function supportsHistoryAPI() {
  return !(!history || !history.pushState);
}

/**
 *
 *
 */
export function isSafari() {
  return /^((?!chrome|android).)*safari|^((?!chrome|android).)*mobile/i.test(navigator.userAgent);
}

/**
 *
 */
function getRelativeLocation() {
  const { pathname = "/", search = "", hash = "" } = window.location;
  return pathname.slice(0, 1) === "/" /*                                */
    ? pathname + search + hash
    : "/" + pathname + search + hash;
}

let initialized = false;

/**
 *
 */
export function initEventHistory() {
  if (initialized) {
    return;
  }

  initialized = true;
  /*                                                                      */
  if (isSafari()) {
    blockInitialPopstate = true;
    eventQBus.on("ftfind.history.push", () => {
      blockInitialPopstate = false;
    });
  }

  if (!supportsHistoryAPI()) {
    /*                                                                     */
    eventQBus.on("ftfind.history.push", () => true);
    eventQBus.on("ftfind.history.merge", () => true);
    eventQBus.on("ftfind.history.shift", () => true);
    return;
  }

  eventQBus.on("ftfind.history.push", (payload: HistoryPushType) => {
    const { topic, data, url, ephemeral = false } = payload || {};
    pushEventState(topic, ephemeral, data, url);
  });

  eventQBus.on("ftfind.history.merge", (payload: HistoryMergeType) => {
    const { topic, data, url } = payload || {};
    mergeEventState(topic, data, url);
  });

  eventQBus.on("ftfind.history.shift", (payload: HistoryShiftType) => {
    /*                                                         */
    /*                                                    */
    const { force = true } = payload || {};
    shiftEventState(force);
  });

  window.addEventListener("popstate", onPopState);
}
