const { event } = window.o_util ?? {};
const { delegate, stop, undelegate, whichTransitionEndEvent } = event ?? {};
export {
  delegate,
  event,
  stop,
  undelegate,
  whichTransitionEndEvent
};
/*                              */
