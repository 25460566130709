const { fragment } = window.o_util ?? {};
const { deserialize, get, push, remove, serialize, set } = fragment ?? {};
export {
  deserialize,
  fragment,
  get,
  push,
  remove,
  serialize,
  set
};
/*                                 */
